.defaultSvg {
  .innerPath,
  .outerLines {
    fill: var(--ion-color-yellow);
  }
}

.bronzeSvg {
  .innerPath,
  .outerLines {
    fill: var(--ion-color-bronze);
  }
}

.silverSvg {
  .innerPath,
  .outerLines {
    fill: var(--ion-color-silver);
  }
}

.goldSvg {
  .innerPath,
  .outerLines {
    fill: var(--ion-color-gold);
  }
}
