.workHistoryItem {
  --background: var(--ion-background-color);

  :hover {
    cursor: pointer;
  }
}

.profileWorkHistoryItem {
  --background: var(--ion-item-background);

  width: 100%;
  margin: 0 auto;
}

.ionItemBody {
  display: flex;
  flex-direction: column;
}

.constructionDatesAndValueContainer {
  display: flex;
  flex-direction: row;

  > p {
    color: var(--ion-color-medium);
    font-size: 0.8rem;
    margin-bottom: 0;
  }
}

.trashIconContainer {
  align-self: baseline;
  padding-top: 0.3rem;

  .trashIcon {
    font-size: 1rem;
    color: var(--ion-color-text);
  }
}

.addressTextContainer {
  .addressText {
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dotDivider {
  padding: 0 0.5rem;
}

.workHistoryItemSection {
  padding: 0;
}

.avatar {
  align-self: baseline;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-light-shade);
  height: 3rem;
  width: 3rem;

  ion-icon {
    margin: 0;
    font-size: 1.2rem;
  }
}

.contractorAvatar {
  --border-radius: 0.01rem;

  align-self: baseline;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-light-shade);
  height: 3rem;
  width: 3rem;

  ion-icon {
    margin: 0;
    font-size: 1.2rem;
  }
}

.contractorOfficeContainer {
  margin-top: 0.5rem;

  .registeredOfficeTitleAddress {
    color: var(--ion-color-medium);
    font-size: 0.9rem;
    margin-bottom: 0;
    white-space: break-spaces;
  }
}

.projectCost {
  white-space: initial;
}

.projectTypeListContainer {
  .projectTypeList {
    color: var(--ion-color-medium);
    font-size: 0.75rem;
    white-space: initial;
  }
}

.validReferenceContainer {
  .verifiedReferenceCount {
    font-weight: bold;
    font-size: 0.75rem;
  }
}

.photosContainer {
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0;

  > div {
    margin-right: 0.25rem;
  }
}

.workHistoryStatus {
  color: var(--ion-color-text);
  margin-top: 0.2rem;
}

.inProgress {
  background-color: var(--ion-color-warning);
}

.waiting {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-item-background);
}

.verified {
  background-color: var(--ion-color-success);
}
