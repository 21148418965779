.pageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.caption {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.notification {
  text-align: center;
  margin: 0 1rem;
}

.button {
  padding: 0.25rem;
  justify-content: end;
}

@media only screen and (min-width: 768px) {
  .notification {
    max-width: 50%;
    margin: 0 auto;
  }
}
