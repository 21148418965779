.projectCard {
  ion-card-title {
    font-size: 1rem;
    font-weight: 600;

    .address {
      margin-bottom: 0;
    }
  }

  ion-card-header {
    padding-bottom: 0;
  }

  margin: 0 0 1.5rem;
}

.projectMemberItem {
  p {
    font-size: 1rem;
    color: var(--ion-text-color);
  }

  .projectMemberLabel {
    cursor: pointer;
  }

  ion-label:last-child {
    margin-top: -0.2rem;

    p {
      font-size: 0.75rem;
      color: var(--ion-color-medium);
    }
  }
}

.showProjectSection {
  padding: 0 1rem;
  margin-bottom: 1.5rem;

  ion-label[slot="end"] {
    margin-right: 1rem;
    color: var(--ion-color-medium);
  }

  .projectHeadings {
    margin-bottom: 0.5rem;
  }

  ion-item {
    --padding-top: 0.2rem;
    --padding-bottom: 0.2rem;

    .projectItemName {
      color: var(--ion-text-color);
      font-size: 1rem;
      margin: 0;
    }
  }

  div[slot="content"] {
    border-bottom: 0.02rem solid var(--ion-color-light-shade);
    background: var(--ion-color-secondary);
  }

  .infoDetail {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    min-height: 4rem;

    ion-text {
      display: flex;
      align-self: center;
      width: 55%;
      margin-left: 1rem;
    }

    .infoDetailValue {
      width: 45%;
      padding-right: 1rem;

      p {
        margin: 0;
      }
    }

    .infoDetailLabel {
      font-weight: 600;
    }

    ion-chip {
      margin-right: 2rem;
      display: flex;
      justify-content: center;
      background-color: var(--ion-color-success-tint);
      color: var(--ion-color-primary);
      font-weight: 600;
    }
  }
}

.membersListBottomMargin {
  font-size: 1rem;
  margin-bottom: 4rem;
}

.iconContainer {
  background-color: var(--ion-color-light);
  display: flex;
  padding: 0.4rem;
  margin-right: 0.85rem;

  ion-icon {
    font-size: 1.3rem;
  }
}

.iconYourTasks {
  background-color: var(--ion-color-tertiary);
}

.chatIconButton {
  border: 0;
  margin: 0;
}

.memberActions {
  display: flex;
  justify-content: flex-end;
}

.projectDocumentCard {
  cursor: pointer;
}

.iconDateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downloadIcon {
  font-size: 1.3rem;
  margin-left: 0.2rem;
  margin-right: 0.5rem;
}

.downloadSpinner {
  font-size: 1.3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  max-width: 1rem;
  max-height: 1.3rem;
}

.map {
  height: 10rem;
  width: 100%;
}

.showProjectHeader {
  ion-button {
    width: 100%;
  }
}
