.message {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 90%;
  width: 100%;
}

.caption {
  font-size: 2rem;
  font-weight: 700;
}

.messageDescription {
  max-width: 19rem;
}

.button {
  width: 100%;
  max-width: 25rem;

  --padding-bottom: 1.2rem;
  --padding-top: 1.2rem;
}
