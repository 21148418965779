.modalContainer {
  padding: 0 1rem 2rem;
}

.phoneInput {
  margin-bottom: 1rem;

  @media (prefers-color-scheme: dark) {
    input {
      background-color: var(--ion-background-color);
      border: 0;
    }

    input:focus {
      outline: none;
    }
  }

  @media (prefers-color-scheme: light) {
    input {
      background-color: var(--ion-background-color);
      border: 0;
    }

    input:focus {
      outline: none;
    }
  }
}
