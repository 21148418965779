.yearContainer {
  background-color: var(--ion-background-color);
  margin: 0 auto;
  text-align: center;
  padding: 0.5rem 0;
  margin-top: 1rem;
  width: calc(100% - 2rem);

  h5 {
    margin: 0;
    font-size: 0.9rem;
  }
}
