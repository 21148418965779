.referenceInputContainer {
  ion-item {
    --border-style: solid;
    --border-width: 1px;
    --border-color: var(--ion-color-medium);
    --border-radius: 0.5em;
  }

  p {
    margin-bottom: 0.5em;
    margin-top: 0;
  }

  .indicatorContainer {
    max-width: 10em;
    margin: auto;
  }
}

.customMobileInput {
  padding: 0;

  h3 {
    padding: 0;
    font-size: 1em;
    color: var(--ion-text-color);
    font-family: var(--common-font);
    margin-bottom: 0;
  }

  ion-title {
    padding: 0;
  }

  ion-card {
    width: 100%;
    box-shadow: none;
    margin-left: auto;
    margin-top: 0.5em;
  }

  ion-col {
    box-shadow: none;
    background-color: var(--ion-background-color);
    padding: 0;

    > div {
      width: 100%;
      background: var(--theme-white);
      padding: 1em;
      border: solid 1px var(--ion-color-medium);
      border-radius: 0.5em;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }

    p {
      margin-top: 0;
      text-align: end;
    }
  }
}

.addReferenceContainer {
  h3 {
    margin-bottom: 1.5em;
  }

  ion-item {
    margin-bottom: 1.2em;
    text-align: start;

    --background: var(--ion-item-background);

    > ion-icon {
      margin-left: 0.2em;
      margin-right: 1em;
      color: var(--ion-color-medium-tint);
    }
  }

  .referenceDetailItem {
    p:not(:first-of-type) {
      margin-top: -0.5em;
      text-transform: lowercase;
    }

    p:not(:first-of-type)::first-letter {
      text-transform: uppercase;
    }
  }
}

.selectReferenceRoleContainer {
  .indicatorContainer {
    max-width: 10em;
    margin: auto;
  }

  h3 {
    margin-bottom: 1em;
  }

  .referenceButtonsContainer {
    display: flex;
    flex-direction: column;

    ion-button {
      --background: var(--ion-item-background);
      --box-shadow: var(--theme-custom-box-shadow);
      --padding-left: 2em;

      color: var(--ion-text-color);
      text-transform: none;
      min-height: 3.5em;
      margin-bottom: 1em;

      span {
        margin-right: auto;
        padding-left: 1em;
      }
    }
  }
}

.referenceInputCoverPage {
  .customIonImg {
    height: 15rem;
  }
}

.ionToolbar {
  --background: transparent;
}
